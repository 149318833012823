:root{
  // Name variables by their section and function
// Breakpoints
--tablet: 768px;
--desktop: 1200px;
--col-height: 600px;
// Fonts
--font: 'Open Sans',
sans-serif;
// Global
--font-regular:Open Sans, Regular;
--font-bold:Open Sans, Bold;
--font-light:Open Sans, Light;
--link-color: #428bca;
--body-background: #FFFFFF;
// Buttons
--button-background: #429BC4;
--button-border: #429bc5;
--button-hover: #429bc5;
--button-hover-border: #429bc5;
--white: #ffffff;
--light-gray: #f4f6f7;
--slate-gray: #D0D5D6;
--gray: #D0D5D6;
--blue-gray: #485f6b;
--bright-blue-faded: #5ec5f0;
--dark-blue: #266885;
--bright-blue: #00abf1;
--pale-blue: #d1e7eb;
--sky-blue: #a1cde2;
--slate-blue: #429bc5;
--blue-black: #1e272c;
--brown-mustard: #bdbe54;
--red: #d15a82;
--green: #6ebaa9;
--dark-pink: #7b2074;
--anchor-blue: #75d6fe;
--button-rollover-bg: #0183b5;
--button-rollover-text: #00abf1;
--text-rollover: #016e98;
--divider-top: #7b2074;
--divider-bottom: --purple;
--purple: #7b2074;
--light-purple: #B259A8;
--blue: #2E8EBC;
--nav-background-mobile: #4c6470;
// Dashboard
--dashboard-sidebar-link: --dark-pink;
--dashboard-sidebar-border: --slate-gray;
// App Tiles
--tile-height: 60px;
--tile-upper-border: --slate-gray;
--tile-icon-bar-text: --white;
--tile-icon-bar: --slate-blue;
--tile-icon-bar-border: --sky-blue;
// Prescribe modal
--prescribe-modal-slide-background: #ffffff;
--prescribe-modal-slide-border: #c7cfd0;
--prescribe-modal-slide-border-active: #38bbf1;
--prescribe-modal-slide-color: #223954;
// Patient Detail
--patient-detail-resend-button-color: #1dacee;
--patient-detail-resend-button-hover: #0183b5;
}
 