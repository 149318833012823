/* You can add global styles to this file, and also import other style files */
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1196px,
);
$body-color: #2d445d;
$primary: #429bc4;
$secondary: #2D445D;
$success: #468847;
$min-contrast-ratio: 3.1;
$border-radius: 8px;
$btn-border-radius-sm: 8px;

$btn-font-size: 16px;
$btn-font-weight: 700;
$btn-padding-y: 9px;
$btn-padding-x: 18px;

/* Card */
$card-border-radius: 8px;
$card-border-color: #D0D5D6;
$card-border-width: 2px;
$card-spacer-x: 7px;

/* Mark */
$mark-bg: #F1F8FB;
$mark-padding: 0.4em;

/* Table */
$table-cell-padding: 0.5rem;
$table-border-color: #D0D5D6;

/* Border */
$border-color: #D0D5D6;

/* Checkbox */
$custom-checkbox-indicator-border-radius: 4px;
 
@import "bootstrap/scss/bootstrap";
@import "./assets/css/Variables.scss";
@import "./assets/css/global.scss";
